<template>
  <div
    @click="handleClose"
    v-if="isModal"
    cy="base-modal"
    class="base-modal z99 flex-center wh100 fixed left0 top0"
  >
    <div class="relative">
      <i class="absolute pointer"
         @click="handleClose">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z" fill="black"/>
        </svg>
      </i>
      <slot />
    </div>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex';
  import { computed, onUpdated } from 'vue';

  const { state, commit } = useStore();
  const isModal = computed(() => state.isModal);

  onUpdated(function () {
    document.body.style.position = isModal.value ? 'fixed' : 'relative';
    document.body.style.overflow = isModal.value ? 'hidden' : 'scroll';
  });

  function handleClose () {
    commit('SET_MODAL', false);
    commit("CLEAR_RESUMES");
  }
</script>

<style lang="scss">
  .base-modal {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(15px);
    @media only screen and (min-width: 0) {
      > div {
        width: 100%;
        max-width: 80vw;
        margin: 24px;
        padding: 24px;
        background-color: white;
        border-radius: 4px;

        i {
          height: 28px;
          top: -14px;
          right: -14px;
          z-index: 999;
          background-color: white;
          border-radius: 50px;
        }
      }
    }

    @media only screen and (max-width: 600px) {
      > div {
        height: 100%;
        overflow: scroll;
      }
    }
  }
</style>
