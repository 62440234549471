import axios from "axios"
import { api } from "./api"
import { format } from 'date-fns'

export const state = {
  tableHeader: [
    {text: 'Message', value: 'message', sortable: true },
    {text: 'Email', value: 'email', sortable: true },
    {text: 'Created at', value: 'createdAt', sortable: true }
  ],
  data: []
}

export const mutations = {
  SET_SUPPORT (state, {key, data}) {
    state[key] = data
  }
}

export const actions = {   
  async get_tickets ({commit}) {     
    try {       
      const { data } = await axios.get(api.support)
      
      const formattedData = data
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .filter(item => item.createdAt)
        .map(item => {
          const words = item.message.split(' ')
          const shortMessage = words.length > 15 
            ? words.slice(0, 15).join(' ') + '...'
            : item.message

          try {
            return {
              ...item,
              message: shortMessage,
              fullMessage: item.message,
              createdAt: format(new Date(item.createdAt), 'dd MMM. yyyy')
            }
          } catch {
            return {
              ...item,
              message: shortMessage,
              fullMessage: item.message,
              createdAt: item.createdAt
            }
          }
        })
      
      commit('SET_SUPPORT', {key: 'data', data: formattedData})     
    } catch (err) {       
      console.error('Eroare:', err)     
    }   
  } 
}