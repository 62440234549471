<template>
  <button class="base-loading-button action-btn flex-center"
          :class="classes"
          :disabled="loading"
          @click="handleClick">
    <span v-if="loading"
          class="loading-spinner" />
    <span v-else>
      <slot />
    </span>
  </button>
</template>

<script setup>
  import {ref } from "vue";

  defineProps({
    name: String,
    loading: Boolean,
    classes: String,
  })
  const emit = defineEmits(["click"])
  const isClicked = ref(false)

  function handleClick () {
    isClicked.value = true
    emit('click')
  }
</script>

<style lang="scss">
  .base-loading-button {
    min-width: 80px;
    @media only screen and (min-width: 0) {
      .loading-spinner {
        width: 20px;
        height: 20px;
        display: block;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-left-color: #ffffff;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
</style>