import axios from "axios"
import { api } from "./api"
import { format, parseISO } from 'date-fns'

const checkIcon = `<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.5575 2.56209L9.56227 18.5589C9.42295 18.6987 9.25741 18.8096 9.07514 18.8853C8.89287 18.961 8.69744 19 8.50008 19C8.30272 19 8.1073 18.961 7.92502 18.8853C7.74275 18.8096 7.57721 18.6987 7.4379 18.5589L0.439971 11.5603C0.300483 11.4208 0.189835 11.2552 0.114345 11.0729C0.0388544 10.8906 2.07854e-09 10.6953 0 10.498C-2.07853e-09 10.3007 0.0388544 10.1054 0.114345 9.9231C0.189835 9.74084 0.300483 9.57522 0.439971 9.43572C0.57946 9.29622 0.745056 9.18556 0.927306 9.11006C1.10956 9.03457 1.30489 8.99571 1.50216 8.99571C1.69942 8.99571 1.89476 9.03457 2.07701 9.11006C2.25926 9.18556 2.42485 9.29622 2.56434 9.43572L8.50133 15.3733L23.4357 0.440014C23.7174 0.158278 24.0994 0 24.4978 0C24.8962 0 25.2783 0.158278 25.56 0.440014C25.8417 0.72175 26 1.10387 26 1.5023C26 1.90074 25.8417 2.28285 25.56 2.56459L25.5575 2.56209Z" fill="#268D4A"/>
</svg>`

export const state = {
  data: [],
  dataCopy: [],
  tableHeader: [
    {text: 'Email', value: 'email'},
    {text: 'Created at', value: 'createdAt', sortable: true},
    {text: 'Credits', value: 'credits'},
    {text: 'Premium', value: 'isPremium', icon: checkIcon, sortable: true},
    {text: 'Bugs', value: 'bugs'},
  ],
  originalResume: null,
  generatedResume: null,
  loading: false
}

export const mutations = {
  SET_USERS (state, {key, data}) {
    state[key] = data
  },
  SEARCH_USER (state, string) {
    if (state.dataCopy.length === 0) {
      state.dataCopy = state.data.slice()
    }
    state.data = state.dataCopy.filter(({email}) => email.toLowerCase().startsWith(string.toLocaleLowerCase()))
    if (!string) {
      state.data = state.dataCopy.slice()
      state.dataCopy = []
    }
  },
  SET_RESUMES(state, { originalResume, generatedResume }) {
    state.originalResume = originalResume
    state.generatedResume = generatedResume
  },
  CLEAR_RESUMES(state) {
    state.originalResume = null
    state.generatedResume = null
  },
  SET_LOADING(state, value) {
    state.loading = value
  }
}

export const actions = {
  async get_users ({commit}) {
    try {
      commit('SET_LOADING', true)
      const { data } = await axios.get(api.users)
      const processedData = data
        .map(user => ({
          ...user,
          timestamp: user.createdAt ? new Date(user.createdAt).getTime() : 0,
          createdAt: user.createdAt ? format(parseISO(user.createdAt), 'dd MMM. yyyy HH:mm') : null
        }))
        .sort((a, b) => b.timestamp - a.timestamp)
        .map(user => {
          const { timestamp, ...rest } = user
          return rest
        })
      
      commit('SET_USERS', {key: 'data', data: processedData})
      commit('SET_LOADING', false)
    } catch (err) {
      commit('SET_LOADING', false)
      console.log('Error:', err)
    }
  },
  async get_user_resume ({commit},{ email, pdfFileName }) {
    try {
      const { data } = await axios.post(`${api.users}/${email}/resumes`, { pdfFileName })
      commit('SET_RESUMES', {
        originalResume: {
          imageBase64: data.originalResume.imageBase64
        },
        generatedResume: {
          imageBase64: data.generatedResume.imageBase64
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
}