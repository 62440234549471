<template>
  <div class="cpanel-layout flex wh100">
    <div class="side-menu h100">
      <img src="../assets/logo.png" alt="logo" />
      <nav class="m-t-24">
        <router-link :to="`/cpanel/${route}`"
                     v-for="({name, route}, index) in menu"
                     :key="index">
          {{ name }}
        </router-link>
      </nav>
    </div>

    <div class="main-content w100">
      <router-view />
    </div>
  </div>
</template>

<script setup>
  import {ref} from "vue";

  const menu = ref([
    {name: 'Users', route: ''},
    {name: 'Prompts', route: 'prompts'},
    {name: 'Errors', route: 'errors'},
    {name: 'Resumes', route: 'resumes'},
    {name: 'Support', route: 'support'},
    {name: 'Resumes preview', route: 'resumes-preview'},
    {name: 'Reports', route: 'reports'},
  ])
</script>

<style lang="scss">
  .cpanel-layout {
    @media only screen and (min-width: 0) {
      .side-menu {
        position: sticky;
        top: 0;
        height: 100vh;
        min-width: 200px;
        padding: 24px;
        background: linear-gradient(0deg, #D9D9D9 0%, #dddddd 100%);
        nav {
          a {
            margin-bottom: 12px;
            display: block;
            font-size: 1.8rem;
            &.router-link-exact-active {
              text-decoration: underline;
            }
          }
        }
      }
      .main-content {
        padding: 24px;
        overflow-y: auto;
      }
    }
  }
</style>