<template>
  <main class="resumes-view">
    <header>
      <h1>
        Resumes list ({{ images.length }})
      </h1>
      <input type="search" @input="handleSearch" placeholder="search email" />
    </header>

    <ul class="w100 flex flex-wrap">
      <li v-for="(item, index) in images"
          :key="index"
          class="relative">
        <button @click="handleDownload(item)"
                class="absolute right0 top0 bg-transparent">
          <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_41_11" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="20">
              <path d="M9.5 1L15 6.5V18C15 18.55 14.55 19 14 19H2C1.45 19 1 18.55 1 18V2C1 1.45 1.45 1 2 1H9.5Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.5 1.5V6H15L10.5 1.5Z" fill="black" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.5 1L15 6.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8 17L12 13H9.5V10H6.5V13H4L8 17Z" fill="black"/>
            </mask>
            <g mask="url(#mask0_41_11)">
              <path d="M20 -2H-4V22H20V-2Z" fill="#1EB386"/>
            </g>
          </svg>
        </button>
        <img :src="`/resumes-images/${item._id}/${item.fullResume}`" alt="" />
      </li>
    </ul>
  </main>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";

  const { state, dispatch, commit } = useStore()
  const images = computed(() => state.resumes.images)
  dispatch('get_resumes_images')

  function handleDownload (item) {
    dispatch('download_resume', item)
  }

  function handleSearch (ev) {
    commit('SEARCH_RESUME', ev.target.value)
  }
</script>

<style lang="scss">
  .resumes-view {
    @media only screen and (min-width: 0) {
      overflow-y: scroll;
      height: 100%;
      img {
        width: 700px;
        margin-right: 16px;
        margin-bottom: 16px;
        border: 1px solid lightgray;
      }
    }
  }
</style>