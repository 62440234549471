<template>
  <div class="resumes-preview">
    <div class="fixed-search-wrapper">
      <h1>Resumes-preview</h1>
      <div class="search-container">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search email..."
          class="search-input"
          @input="handleSearch"
        />
      </div>
    </div>

    <div v-if="loading">
      <BaseLoader />
    </div>
    <div v-else class="resume-details">
      <div v-if="searchQuery && !filteredResumeData.length" class="no-results">
        No results found for "{{ searchQuery }}"
      </div>
      <BaseFeedCard
        v-else-if="filteredResumeData.length"
        :data="filteredResumeData"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import BaseFeedCard from "@/components/BaseFeedCard.vue";
import BaseLoader from "@/components/BaseLoader.vue";

const { state, dispatch } = useStore();
const resumeData = computed(() => state.resumes.feedResumes);
const loading = computed(() => state.resumes.loading);
const searchQuery = ref("");
const searchTimeout = ref(null);

const filteredResumeData = computed(() => {
  if (!searchQuery.value) return resumeData.value;

  const query = searchQuery.value.toLowerCase();
  return resumeData.value.filter((item) =>
    item._id.toLowerCase().includes(query)
  );
});

function handleSearch() {
  searchTimeout.value && clearTimeout(searchTimeout.value);
  searchTimeout.value = setTimeout(() => {
  }, 300);
}

dispatch("get_resumes_preview");
</script>

<style lang="scss">
.resumes-preview {
  .fixed-search-wrapper {
    position: fixed;
    top: 0;
    left: 200px;
    right: 18px;
    background: white;
    z-index: 1;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h1 {
      margin-bottom: 20px;
    }
  }

  .search-container {
    background: white;
    display: flex;
    align-items: center;

    .search-input {
      width: 20%;
      padding: 10px 40px 10px 15px;
      border: 2px solid #ddd;
      border-radius: 4px;
      font-size: 16px;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: #3498db;
      }
    }
  }

  .resume-details {
    padding-top: 120px;
  }

  .no-results {
    text-align: center;
    padding: 20px;
    color: #666;
    font-size: 16px;
  }
}
</style>