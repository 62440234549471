import axios from "axios"
import { api } from './api'

export const state = {
  data: {},
  jobDescription: '',
  generatedImprovementsForCV1: {},
  generatedImprovementsForCV2: {},
  refinedCvContent: {},
  jdAnalyzeResponse: {},
  newResumeJsonStructure: {},
  isLoading: false,
  styleTemplates: []
}

export const mutations = {
  SET_PROMPT (state, {key, data}) {
    state[key] = data
  },
  SET_PROMPT_LOADING (state, value) {
    state.isLoading = value
  }
}

export const actions = {
  async get_prompts ({commit}) {
    try {
      const url = `${api.sandbox}/prompts`
      const { data } = await axios.get(url)
      commit('SET_PROMPT', {key: 'data', data})
    } catch (err) {
      console.log(err)
    }
  },
  async update_prompt ({state, commit}, promptsKeysList) {
    try {
      commit('SET_PROMPT_LOADING', true)
      const url = `${api.sandbox}/prompts`
      const payload = promptsKeysList.reduce((acc, key) => {
        acc[key] = state.data[key]
        return acc
      }, {})
      await axios.put(url, payload)
      commit('SET_PROMPT_LOADING', false)
    } catch (err) {
      console.log(err)
      commit('SET_PROMPT_LOADING', false)
    }
  },
  async run_refine_content ({commit}) {
    try {
      commit('SET_PROMPT_LOADING', true)
      const url = `${api.sandbox}/resume/refine`
      const { data } = await axios.post(url, {})
      commit('SET_PROMPT', {key: 'refinedCvContent', data})
      commit('SET_PROMPT_LOADING', false)
    } catch (err) {
      console.log(err)
      commit('SET_PROMPT_LOADING', false)
    }
  },
  async run_first_improvement ({commit}) {
    try {
      commit('SET_PROMPT_LOADING', true)
      const url = `${api.sandbox}/resume/improvements1`
      const { data } = await axios.post(url, {})
      commit('SET_PROMPT', {key: 'generatedImprovementsForCV1', data})
      commit('SET_PROMPT_LOADING', false)
    } catch (err) {
      console.log(err)
      commit('SET_PROMPT_LOADING', false)
    }
  },
  async run_second_improvement ({commit}) {
    try {
      commit('SET_PROMPT_LOADING', true)
      const url = `${api.sandbox}/resume/improvements2`
      const { data } = await axios.post(url, {})
      commit('SET_PROMPT', {key: 'generatedImprovementsForCV2', data})
      commit('SET_PROMPT_LOADING', false)
    } catch (err) {
      console.log(err)
      commit('SET_PROMPT_LOADING', false)
    }
  },
  async run_jd_analyzer ({state, commit}) {
    try {
      commit('SET_PROMPT_LOADING', true)
      const url = `${api.sandbox}/resume/analyze-job`
      const { data } = await axios.post(url, {jobDescription: state.jobDescription})
      commit('SET_PROMPT', {key: 'jdAnalyzeResponse', data})
      commit('SET_PROMPT_LOADING', false)
    } catch (err) {
      console.log(err)
      commit('SET_PROMPT_LOADING', false)
    }
  },
  async run_resume_prompt ({commit}) {
    try {
      commit('SET_PROMPT_LOADING', true)
      const url = `${api.sandbox}/resume/generate`
      const { data } = await axios.post(url, {})
      commit('SET_PROMPT', {key: 'newResumeJsonStructure', data})
      commit('SET_PROMPT_LOADING', false)
    } catch (err) {
      console.log(err)
      commit('SET_PROMPT_LOADING', false)
    }
  },
  async upload_resume ({commit}, formData) {
    try {
      commit('SET_PROMPT_LOADING', true)
      const url = `${api.sandbox}/resume/upload`
      await axios.post(url, formData)
      commit('SET_PROMPT_LOADING', false)
    } catch (err) {
      console.log(err)
      commit('SET_PROMPT_LOADING', false)
    }
  },
  async resume_build ({commit, dispatch}, {userId, format}) {
    try {
      commit('SET_PROMPT_LOADING', true)
      const url = `${api.sandbox}/resume/build/`
      const { data: { pdfFileName, docxFileName } } = await axios.post(url, {})
      dispatch('download_generated_resume', { userId, originalFileName: format === 'docx' ? docxFileName : pdfFileName})
      commit('SET_PROMPT_LOADING', false)
    } catch (err) {
      console.log(err)
      commit('SET_PROMPT_LOADING', false)
    }
  },
  async select_template ({commit, dispatch}, templateCV) {
    try {
      const url = `${api.sandbox}/resume/template`
      await axios.put(url, {templateCV})
    } catch (err) {
      console.log(err);
    }
  },
  async get_styleTemplates ({commit}) {
    try {
      const url = `${api.sandbox}/templates`
      const {data} = await axios.get(url)
      commit('SET_PROMPT', {key: 'styleTemplates', data})
    } catch (err) {
      console.log(err);
    }
  },
  async update_styleTemplates ({commit}, styleTemplates) {
    try {
      commit('SET_PROMPT_LOADING', true)
      const url = `${api.sandbox}/templates`
      await axios.put(url, styleTemplates)
      commit('SET_PROMPT_LOADING', false)
    } catch (err) {
      commit('SET_PROMPT_LOADING', false)
      console.log(err);
    }
  },
}
