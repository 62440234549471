<template>
  <div class="json-preview-component">
    <div class="flex">
      <i class="pointer m-r-16 m-left-auto"
         @click="viewJsonResult">
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.544 7.045C20.848 7.471 21 7.685 21 8C21 8.316 20.848 8.529 20.544 8.955C19.178 10.871 15.689 15 11 15C6.31 15 2.822 10.87 1.456 8.955C1.152 8.529 1 8.315 1 8C1 7.684 1.152 7.471 1.456 7.045C2.822 5.129 6.311 1 11 1C15.69 1 19.178 5.13 20.544 7.045Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7956 5 11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11C11.7956 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </i>

      <i class="pointer copy-icon"
         @click="copyToClipboard">
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 0C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V14C18 14.5304 17.7893 15.0391 17.4142 15.4142C17.0391 15.7893 16.5304 16 16 16H14V18C14 18.5304 13.7893 19.0391 13.4142 19.4142C13.0391 19.7893 12.5304 20 12 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V6C0 5.46957 0.210714 4.96086 0.585786 4.58579C0.960859 4.21071 1.46957 4 2 4H4V2C4 1.46957 4.21071 0.960859 4.58579 0.585786C4.96086 0.210714 5.46957 0 6 0H16ZM12 6H2V18H12V6ZM7 13C7.26522 13 7.51957 13.1054 7.70711 13.2929C7.89464 13.4804 8 13.7348 8 14C8 14.2652 7.89464 14.5196 7.70711 14.7071C7.51957 14.8946 7.26522 15 7 15H5C4.73478 15 4.48043 14.8946 4.29289 14.7071C4.10536 14.5196 4 14.2652 4 14C4 13.7348 4.10536 13.4804 4.29289 13.2929C4.48043 13.1054 4.73478 13 5 13H7ZM16 2H6V4H12C12.5304 4 13.0391 4.21071 13.4142 4.58579C13.7893 4.96086 14 5.46957 14 6V14H16V2ZM9 9C9.25488 9.00028 9.50003 9.09788 9.68537 9.27285C9.8707 9.44782 9.98224 9.68695 9.99717 9.94139C10.0121 10.1958 9.92933 10.4464 9.76574 10.6418C9.60215 10.8373 9.3701 10.9629 9.117 10.993L9 11H5C4.74512 10.9997 4.49997 10.9021 4.31463 10.7272C4.1293 10.5522 4.01777 10.313 4.00283 10.0586C3.98789 9.80416 4.07067 9.55362 4.23426 9.35817C4.39786 9.16271 4.6299 9.0371 4.883 9.007L5 9H9Z" fill="black"/>
        </svg>
      </i>
    </div>

    <div class="json-preview">
      <pre>{{ jsonData }}</pre>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    name: String,
    jsonData: Object
  })

  const emit = defineEmits(['view'])

  function viewJsonResult () {
    emit('view', props.jsonData)
  }

  function copyToClipboard () {
    navigator.clipboard.writeText(props.jsonData)
      .then(() => {
        console.log('Text copied to clipboard')
        // You can add user feedback here, like a temporary message
      })
      .catch(err => {
        console.error('Failed to copy text: ', err)
      })
  }
</script>

<style lang="scss">
  @import "../styles/vars.scss";

  .json-preview-component {
    @media only screen and (min-width: 0) {
      .copy-icon {
        svg:active {
          path {
            fill: $green;
          }
        }
      }

      .json-preview {
        height: 200px;
        margin-top: 8px;
        overflow-y: scroll;
        border: 1px solid lightgray;
      }
    }
  }
</style>