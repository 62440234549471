<template>
  <div class="prompts-view">
    <BaseModal>
      <div class="json-result-wrapper">
        <pre>{{ jsonResult }}</pre>
      </div>
    </BaseModal>

    <h1>Prompts</h1>

    <header class="m-b-16">
      <details class="pointer">
        <summary>Job description</summary>
        <textarea
          :value="jobDescription"
          @input="handleJobDescription"
          placeholder="job description"
        />
      </details>
    </header>

    <div class="flex">
      <section>
        <div>
          <h2>1. Upload resume</h2>
          <p>
            This is an optional section, it just analyzes the resume to give
            hints.
          </p>
          <BaseLoadingInput @change="uploadResume" :loading="handleIsLoading('fileUpload')" />
          <span class="block m-t-8">
            {{ user.originalFileName }}
          </span>
          <br />
          <div v-for="(template, key) in styleTemplates" :key="key">
            <h3 class="m-b-6">Edit {{ key }} template:</h3>
              <textarea
                 @input="handleTemplateChange(key, 'html', $event.target.value)"
                  v-model="template.html"
                  class="m-t-16"
              />
              <textarea
                  @input="handleTemplateChange(key, 'css', $event.target.value)"
                  v-model="template.css"
                  class="m-t-16"
              />
              <BaseLoadingButton 
                class="m-t-8"
                :loading="handleIsLoading('templateUpdate')" 
                @click="saveTemplate(key)">
                  SAVE
              </BaseLoadingButton>
    </div>
        </div>
      </section>

      <section>
        <div>
          <h2>2. Refine content</h2>
          <p>
            This is the step which parses the pdf to content and use gpt to
            generate json of content.
          </p>

          <textarea
            class="m-t-16"
            v-model="prompts.refineStructureResumePrompt"
          />

          <BaseLoadingButton
            :loading="handleIsLoading('refineContent')"
            @click="handleRefineContent"
          >
            RUN
          </BaseLoadingButton>
        </div>

        <BaseJsonPreview
          :json-data="refinedCvContent"
          @copy="copyToClipboard"
          @view="viewJsonResult"
        />
      </section>

      <section class="generate-column">
        <div>
          <h2>3. Generate</h2>

          <div>
            <label for="">
              <span> Job description analyzer </span>
              <textarea v-model="prompts.jobDescriptionAnalyzerPrompt" />
            </label>

            <BaseLoadingButton
              :loading="handleIsLoading('jdAnalyze')"
              @click="runJDanalyzer"
            >
              RUN
            </BaseLoadingButton>
          </div>

          <label for="">
            <span> Resume prompt system </span>
            <textarea v-model="prompts.resumePromptSystem" />
          </label>
          <label for="">
            <span> Resume prompt user </span>
            <textarea v-model="prompts.resumePromptUser" />
          </label>
          <select class="m-t-16 selected-template" v-model="templateCV">
            <option
              v-for="template in templates"
              :key="template"
              :value="template"
            >
              {{ template }}
            </option>
          </select>
          <BaseLoadingButton
            :loading="handleIsLoading('resumePrompt')"
            @click="runFinalResumePrompt"
          >
            RUN
          </BaseLoadingButton>

          <div class="resume-download flex">
            <button class="m-r-16" @click="handleResumeDownload('docx')">
              DOCX
            </button>
            <button @click="handleResumeDownload('pdf')">PDF</button>
          </div>
        </div>

        <BaseJsonPreview
          :json-data="jdAnalyzeResponse"
          @copy="copyToClipboard"
          @view="viewJsonResult"
        />

        <BaseJsonPreview
          :json-data="newResumeJsonStructure"
          @copy="copyToClipboard"
          @view="viewJsonResult"
        />
      </section>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import BaseLoadingButton from "@/components/BaseLoadingButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import BaseJsonPreview from "@/components/BaseJsonPreview.vue";
import BaseLoadingInput from "@/components/BaseLoadingInput.vue";

const { state, dispatch, commit } = useStore();
dispatch("get_prompts");
const isLoading = computed(() => state.prompts.isLoading);
const user = computed(() => state.auth.user);
const templateCV = computed({
  get: () => state.auth.user.templateCV,
  set: function (value) {
    commit("SET_TEMPLATE", value);
    dispatch("select_template", value);
  },
});
const prompts = computed(() => state.prompts.data);
const styleTemplates = computed(() => state.prompts.styleTemplates); 
const pendingChanges = ref({});
const generatedImprovementsForCV1 = computed(() =>
  JSON.stringify(state.prompts.generatedImprovementsForCV1, null, 2)
);
const generatedImprovementsForCV2 = computed(() =>
  JSON.stringify(state.prompts.generatedImprovementsForCV2, null, 2)
);
const refinedCvContent = computed(() =>
  JSON.stringify(state.prompts.refinedCvContent, null, 2)
);
const jdAnalyzeResponse = computed(() =>
  JSON.stringify(state.prompts.jdAnalyzeResponse, null, 2)
);
const newResumeJsonStructure = computed(() =>
  JSON.stringify(state.prompts.newResumeJsonStructure, null, 2)
);
const jobDescription = computed(() => state.prompts.jobDescription);
const formData = new FormData();
const activePrompt = ref("");
const jsonResult = ref("");
const templates = ["simple", "complex"];
function viewJsonResult(json) {
  jsonResult.value = json;
  commit("SET_MODAL", true);
}

onMounted(() => {
  commit("SET_PROMPT", {
    key: "jobDescription",
    data: localStorage.getItem("jobDescription"),
  });
  dispatch("get_styleTemplates")
});

function handleResumeDownload(format) {
  dispatch("resume_build", { userId: user.value.name, format });
}

function copyToClipboard(textToCopy) {
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      console.log("Text copied to clipboard");
      // You can add user feedback here, like a temporary message
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err);
    });
}

function handleIsLoading(name) {
  return activePrompt.value === name && isLoading.value;
}

function handleJobDescription(ev) {
  localStorage.setItem("jobDescription", ev.target.value);
  commit("SET_PROMPT", { key: "jobDescription", data: ev.target.value });
}

function uploadResume(ev) {
  activePrompt.value = "fileUpload";
  const formData = new FormData();
  formData.append("file", ev.target.files[0]);
  dispatch("upload_resume", formData);
}

async function runFirstImprovement() {
  activePrompt.value = "firstImprovement";
  await dispatch("update_prompt", ["analyzeResumeForImprovementsPrompt1"]);
  await dispatch("run_first_improvement");
}

async function runSecondImprovement() {
  activePrompt.value = "secondImprovement";
  await dispatch("update_prompt", ["analyzeResumeForImprovementsPrompt2"]);
  await dispatch("run_second_improvement");
}

async function handleRefineContent() {
  activePrompt.value = "refineContent";
  await dispatch("update_prompt", ["refineStructureResumePrompt"]);
  await dispatch("run_refine_content");
}

async function runJDanalyzer() {
  activePrompt.value = "jdAnalyze";
  await dispatch("update_prompt", ["jdAnalyzeResponse"]);
  await dispatch("run_jd_analyzer");
}

async function runFinalResumePrompt() {
  activePrompt.value = "resumePrompt";
  await dispatch("update_prompt", ["resumePromptSystem", "resumePromptUser"]);
  await dispatch("run_resume_prompt");
}

const handleTemplateChange = (templateKey, field, value) => {
  if (!pendingChanges.value[templateKey]) {
    pendingChanges.value[templateKey] = {
      html: styleTemplates.value[templateKey].html,
      css: styleTemplates.value[templateKey].css
    };
  }
  pendingChanges.value[templateKey][field] = value;
};

const saveTemplate = (templateKey) => {
  const styledTemplateData = {
    [templateKey]: {
      css: pendingChanges.value[templateKey].css,
      html: pendingChanges.value[templateKey].html
    }
  };
  dispatch("update_styleTemplates", styledTemplateData);
};
</script>

<style lang="scss">
@import "../styles/vars.scss";

.prompts-view {
  position: relative;
  @media only screen and (min-width: 0) {
    .json-result-wrapper {
      overflow-y: scroll;
      max-height: 80vh;
    }

    header {
      margin-bottom: 16px;

      textarea {
        width: 50%;
        height: 80px;
        padding: 6px;
        margin-top: 8px;
      }
    }

    section {
      width: 33%;
      min-width: 420px;
      margin-right: 24px;
      padding: 16px 24px;
      border: 1px solid lightgray;
      border-radius: 4px;

      .json-preview-component {
        margin-top: 16px;
      }

      .base-loading-button {
        margin-top: 16px;
        margin-left: auto;
      }

      h2 {
        margin-top: 0;
      }

      textarea {
        width: 100%;
        height: 160px;
      }

      div {
        button:not(.base-loading-button) {
          border: none;
          background-color: transparent;
        }
        pre {
          white-space: break-spaces;
        }
      }

      .resume-download {
        button {
          background-color: $green !important;
          color: white;
        }
      }
    }
    .generate-column {
      label {
        display: block;
        margin-top: 16px;

        textarea {
          height: 120px;
          margin-top: 4px;
        }
      }
      .selected-template {
        display: block;
        margin-left: auto;
      }
    }
  }
}
</style>