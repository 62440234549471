<template>
  <div class="support-view">
    <h1>
      Support Tickets
    </h1>
    <div class="table">
      <EasyDataTable 
        :headers="tableHeader"
        :items="data"
        :rows-per-page="100"
        :buttons-pagination="false"
        header-text-direction="center"
        table-class-name="customize-table"
        border-cell
        alternating
      >
        <template #item-message="item">
          <div :title="item.fullMessage">
            {{ item.message }}
          </div>
        </template>
      </EasyDataTable>
    </div>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed} from "vue";

  const { state, dispatch, commit } = useStore()
  dispatch('get_tickets')
  const tableHeader = computed(() => state.support.tableHeader)
  const data = computed(() => state.support.data)


</script>

<style lang="scss">
  .support-view {
    @media only screen and (min-width: 0) {
      .table {
        .customize-table {
          --easy-table-header-font-color: #fff;
          --easy-table-header-background-color: #1eb386;
          --easy-table-header-font-size: 18px;
          --easy-table-body-row-hover-background-color: #8c8c8c;
          --easy-table-body-row-hover-font-color: #fff;
          --easy-table-header-item-padding: 15px 10px;
          .vue3-easy-data-table__main {
          overflow-y: scroll;
          height: 73vh;
          }
          .vue3-easy-data-table__header {
            position: sticky;
            top: 0;
            z-index: 1;
          }
        }
      }
    }
  }
</style>