import axios from "axios"
import { api } from "./api"
import { format } from 'date-fns'

export const state = {
  images: [],
  imagesCopy: [],
  feedResumes: [],
  loading: false
}

export const mutations = {
  SET_RESUMES(state, { key, data }) {
    const formatDate = date => {
      const parsedDate = new Date(date)
      return isNaN(parsedDate.getTime()) 
        ? date 
        : format(parsedDate, 'dd MMM. yyyy HH:mm')
    }
  
    state[key] = key === 'feedResumes'
      ? data.map(item => ({
          ...item,
          updatedAt: formatDate(item.updatedAt)
        }))
      : data
  },
  SEARCH_RESUME (state, string) {
    if (!state.imagesCopy.length) {
      state.imagesCopy = state.images.slice()
    }
    state.images = state.images.filter(({userId}) => userId.toLowerCase().startsWith(string.toLowerCase()))
    if (!string) {
      state.images = state.imagesCopy.slice()
      state.imagesCopy = []
    }
  },
  SET_LOADING (state, value) {
    state.loading = value
  }
}

export const actions = {
  async get_resumes_images ({commit}) {
    try {
      const url = `${api.resumes}/images`
      const { data } = await axios.get(url)
      commit('SET_RESUMES', {key: 'images', data})
    } catch (err) {
      console.log(err)
    }
  },
  async download_resume ({ commit }, { userId, originalFileName }) {
    try {
      const url = `${api.resume}/${userId}/${originalFileName}`
      const response = await axios.get(url, {
        responseType: 'blob'  // Important for binary files like PDFs
      })

      // Create a blob from the PDF data
      const blob = new Blob([response.data], { type: 'application/pdf' })

      // Create a temporary URL for the blob
      const downloadUrl = window.URL.createObjectURL(blob)

      // Create a temporary link element
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = originalFileName  // Set the download filename

      // Append to document, click it, and remove it
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Clean up the temporary URL
      window.URL.revokeObjectURL(downloadUrl)
    }
    catch (err) {
      console.log(err)
    }
  },
  async download_generated_resume ({ commit }, { userId, originalFileName }) {
    try {
      const url = `${api.generatedResume}/${userId}/${originalFileName}`
      const response = await axios.get(url, {
        responseType: 'blob'  // Important for binary files like PDFs
      })

      // Create a blob from the PDF data
      const blob = new Blob([response.data], { type: 'application/pdf' })

      // Create a temporary URL for the blob
      const downloadUrl = window.URL.createObjectURL(blob)

      // Create a temporary link element
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = originalFileName  // Set the download filename

      // Append to document, click it, and remove it
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      // Clean up the temporary URL
      window.URL.revokeObjectURL(downloadUrl)
    }
    catch (err) {
      console.log(err)
    }
  },
  async get_resumes_preview ({commit}) {
    try {
      commit('SET_LOADING', true)
      const url = `${api.resumes}/images-feed`
      const { data } = await axios.get(url)
      commit('SET_RESUMES', {key: 'feedResumes', data})
      commit('SET_LOADING', false)
    } catch (err) {
      commit('SET_LOADING', false)
      console.log(err)
    }
  },
}