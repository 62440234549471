import { createStore } from 'vuex'
import * as auth from './modules/auth'
import * as prompts from './modules/prompts'
import * as users from './modules/users'
import * as errors from './modules/errors'
import * as resumes from './modules/resumes'
import * as support from './modules/support'
import * as reports from './modules/reports'

export default createStore({
  state: {
    isModal: false
  },
  getters: {
  },
  mutations: {
    SET_MODAL (state, value) {
      state.isModal = value
    }
  },
  actions: {
  },
  modules: {
    auth,
    prompts,
    users,
    errors,
    resumes,
    support,
    reports,
  }
})
