import axios from "axios"
import {api} from "./api"

export const state = {
    data: [],
    loading: false,
    users: [],
}

export const mutations = {
  SET_REPORTS (state, {key, data}) {
    state[key] = data
  },
  SET_LOADING(state, value) {
    state.loading = value
}
}

export const actions = {
  async get_reports({ commit }) {
    try {
      commit('SET_LOADING', true);
      const reportsPromise = axios.get(api.reports);
      const userReportsPromise = axios.get(`${api.reports}/users`);

      const [reportsResponse, userReportsResponse] = await Promise.all([
        reportsPromise,
        userReportsPromise
      ]);

      commit('SET_REPORTS', { key: 'data', data: reportsResponse.data });
      commit('SET_REPORTS', { key: 'users', data: userReportsResponse.data });
      commit('SET_LOADING', false);
    } catch (err) {
      commit('SET_LOADING', false);
      console.log(err);
    }
  }
};