<template>
  <label class="base-input">
    <span>
      {{ label || name }}
    </span>
    <br>
    <input :type="type"
           :placeholder="placeholder || name"
           @input="handleInput" />
  </label>
</template>

<script setup>
  const props = defineProps({
    name: { type: String, required: true },
    label: { type: String },
    placeholder: { type: String },
    type: { type: String, default: 'text' },
  })

  const emit = defineEmits(['input'])

  function handleInput (ev) {
    emit('input', {
      name: props.name,
      value: ev.target.value
    })
  }
</script>

<style lang="scss">
  .base-input {
    @media only screen and (min-width: 0) {
      display: block;
      input {
        width: 100%;
        margin-top: 4px;
        padding: 8px 0 8px 8px;
        border: 1px solid lightgray;
        font-size: 16px;
        border-radius: 4px;
      }
    }
  }
</style>