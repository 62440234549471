<template>
  <div class="reports-view">
    <h1>Reports View</h1>
    <div v-if="loading">
      <BaseLoader />
    </div>
    <div v-else>
      <BaseChart
        main-title="Resume Downloads"
        :data="downloads"
        :dataset-configs="[
          {
            field: 'docx_downloaded',
            label: 'DOCX Downloads',
            backgroundColor: '#36A2EB',
            borderColor: '#2693e6',
          },
          {
            field: 'pdf_downloaded',
            label: 'PDF Downloads',
            backgroundColor: '#FF6384',
            borderColor: '#ff4c75',
          },
        ]"
      />
      <BaseChart
        main-title="New Users"
        :data="formattedUsers"
        :dataset-configs="[
          {
            field: 'noOfUsers',
            label: 'Free Users',
            backgroundColor: '#a1d3f1',
            borderColor: '#a1d3f1',
          },
          {
            field: 'noOfPremiumUsers',
            label: 'Premium Users',
            backgroundColor: '#20b386',
            borderColor: '#20b386',
          },
          {
            field: 'noOfPremiumUsersSameDay',
            label: 'Immediate Premium Upgrades',
            backgroundColor: '#f273e1',
            borderColor: '#f273e1',
          },
        ]"
      />
    </div>
  </div>
</template>

<script setup>
import BaseChart from "@/components/BaseChart.vue";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import BaseLoader from "@/components/BaseLoader.vue";

const { state, dispatch } = useStore();

const downloads = computed(() => state.reports.data);
const loading = computed(() => state.reports.loading);
const users = computed(() => state.reports.users);

const formattedUsers = computed(() => {
  const createUserObj = (day, type, value) => ({
    day,
    noOfUsers: type === "users" ? value : 0,
    noOfPremiumUsers: type === "premium" ? value : 0,
    noOfPremiumUsersSameDay: type === "sameDay" ? value : 0,
  });
  const processData = (accumulator, userArray, type) => {
    userArray?.forEach((obj) => {
      const value =
        type === "users"
          ? obj.noOfUsers
          : type === "premium"
          ? obj.noOfPremiumUsers
          : obj.noOfPremiumUsersSameDay;

      const existingDay = accumulator.find((item) => item.day === obj.day);

      if (existingDay) {
        existingDay[
          `noOf${
            type === "users"
              ? "Users"
              : type === "premium"
              ? "PremiumUsers"
              : "PremiumUsersSameDay"
          }`
        ] = value;
      } else {
        accumulator.push(createUserObj(obj.day, type, value));
      }
    });
    return accumulator;
  };
  return ["users", "premium", "sameDay"]
    .reduce(
      (acc, type, index) => processData(acc, users.value[index], type),
      []
    )
    .sort((a, b) => new Date(a.day) - new Date(b.day));
});

onMounted(() => {
  dispatch("get_reports");
});
</script>

<style lang="scss" scoped>
</style>