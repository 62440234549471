<template>
  <div class="upload-file-wrapper">
    <label for="file-upload" :class="{ isloading: loading }">
      <span v-if="loading" class="loading-spinner" />
      <span id="file-upload-text" v-else>UPLOAD FILE</span>
    </label>
    <input
      id="file-upload"
      type="file"
      ref="fileInput"
      @change="handleFileChange"
    />
  </div>
</template>

<script setup>
  import { ref } from "vue";

  const props = defineProps({
    loading: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["change"]);
  const fileInput = ref(null);

  function handleFileChange(event) {
    const files = event.target.files;
    if (!files || !files.length) return;
    emit("change", event);
  }
</script>
  
<style lang="scss">
  @import "../styles/vars.scss";

  .upload-file-wrapper {
    position: relative;
    > label {
      position: absolute;
      background-color: $light-green;
      padding: 7px 5px;
      width: 106px;
      border-radius: 4px;
      top: -3px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.isloading {
        .loading-spinner {
          width: 15px;
          height: 15px;
          display: block;
          border: 2px solid rgba(255, 255, 255, 0.2);
          border-left-color: #ffffff;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }
      }
    }
    #file-upload-text {
      font-size: 13px;
    }
    #file-upload {
      margin-left: 4px;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
</style>