<template>
  <div class="loaders-container">
    <div class="fancy-loader">
      <div class="orbital-loader">
        <div class="center-circle"></div>
        <div class="orbital orbit-1">
          <div class="planet"></div>
        </div>
        <div class="orbital orbit-2">
          <div class="planet"></div>
        </div>
        <div class="orbital orbit-3">
          <div class="planet"></div>
        </div>
        <div class="particle-ring">
          <div class="particle p1"></div>
          <div class="particle p2"></div>
          <div class="particle p3"></div>
          <div class="particle p4"></div>
          <div class="particle p5"></div>
          <div class="particle p6"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
@import "../styles/vars.scss";

@mixin center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin orbital-setup($size) {
  width: $size;
  height: $size;
  margin-left: -$size/2;
  margin-top: -$size/2;
}

// Keyframes
@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { transform: translate(-50%, -50%) scale(0.9); opacity: 0.8; }
  50% { transform: translate(-50%, -50%) scale(1.1); opacity: 1; }
  100% { transform: translate(-50%, -50%) scale(0.9); opacity: 0.8; }
}

@keyframes particlePulse {
  0% { 
    transform: scale(1); 
    opacity: 1; 
    box-shadow: 0 0 10px $loader-color;
  }
  50% { 
    transform: scale(1.5); 
    opacity: 0.5; 
    box-shadow: 0 0 20px $loader-color;
  }
  100% { 
    transform: scale(1); 
    opacity: 1; 
    box-shadow: 0 0 10px $loader-color;
  }
}

.loaders-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.fancy-loader {
  position: relative;
  width: 800px;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    .orbital {
      border-color: rgba($loader-color, 0.4);
      transition: all 0.3s ease;
    }

    .center-circle {
      box-shadow: 0 0 30px $loader-color;
      transition: all 0.3s ease;
    }

    .planet {
      box-shadow: 0 0 15px $loader-color;
      transition: all 0.3s ease;
    }
  }
}

.orbital-loader {
  position: relative;
  width: 400px;
  height: 400px;
}

.center-circle {
  @include center-absolute;
  width: 50px;
  height: 50px;
  background: $loader-color;
  border-radius: 50%;
  box-shadow: 0 0 20px $loader-color;
  animation: pulse 2s ease-in-out infinite;
}

.orbital {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgba($loader-color, 0.2);
  border-radius: 50%;

  .planet {
    position: absolute;
    width: $planet-size;
    height: $planet-size;
    background: $loader-color;
    border-radius: 50%;
    box-shadow: 0 0 10px $loader-color;
  }

  &.orbit-1 {
    @include orbital-setup(80px);
    animation: rotate 3s linear infinite;
    .planet { top: -$planet-size/2; left: 50%; transform: translateX(-50%); }
  }

  &.orbit-2 {
    @include orbital-setup(120px);
    animation: rotate 5s linear infinite reverse;
    .planet { bottom: -$planet-size/2; left: 50%; transform: translateX(-50%); }
  }

  &.orbit-3 {
    @include orbital-setup(160px);
    animation: rotate 7s linear infinite;
    .planet { top: 50%; right: -$planet-size/2; transform: translateY(-50%); }
  }
}

.particle-ring {
  @include center-absolute;
  width: 180px;
  height: 180px;

  .particle {
    position: absolute;
    width: $particle-size;
    height: $particle-size;
    background: $loader-color;
    border-radius: 50%;
    animation: particlePulse 2s ease-in-out infinite;

    @for $i from 1 through 6 {
      &.p#{$i} {
        animation-delay: ($i - 1) * 0.3s;
      }
    }

    &.p1 { top: 0; left: 50%; transform: translateX(-50%); }
    &.p2 { top: 25%; right: 0; }
    &.p3 { bottom: 25%; right: 0; }
    &.p4 { bottom: 0; left: 50%; transform: translateX(-50%); }
    &.p5 { bottom: 25%; left: 0; }
    &.p6 { top: 25%; left: 0; }
  }
}
</style>