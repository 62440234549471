<template>
  <main class="cpanel-view">
    <h1>Users View ({{ data.length }} | {{ premiumUsers }})</h1>
    <div v-if="isLoading" >
          <BaseLoader/>
    </div>
    <div v-else>
      <div class="flex">
        <input
          class="block m-left-auto search-user"
          type="search"
          placeholder="search user"
          @input="handleSearch"
        />
      </div>
      <div class="table">
        <EasyDataTable 
          :headers="tableHeader"
          :items="data"
          :rows-per-page="100"
          :buttons-pagination="false"
          header-text-direction="center"
          table-class-name="customize-table"
          border-cell
          alternating
        >
          <template #item-isPremium="{ isPremium }">
            <div class="premium-icon" v-if="isPremium" v-html="tableHeader[3].icon"></div>
          </template>
        </EasyDataTable>
      </div>
    </div>
  </main>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import BaseLoader from "@/components/BaseLoader.vue";

const { state, dispatch, commit } = useStore();
const selectedResume = ref(null);
dispatch("get_users");
const data = computed(() => state.users.data);
const tableHeader = computed(() => state.users.tableHeader);
const isLoading = computed(() => state.users.loading);
const premiumUsers = computed(() => {
  return data.value.filter(user => user.isPremium).length;
});

function handleSearch(ev) {
  commit("SEARCH_USER", ev.target.value);
}

function handleResumeClick({ email, pdfFileName }) {
  selectedResume.value = pdfFileName;
  commit("SET_MODAL", true);
  dispatch("get_user_resume", { email, pdfFileName });
}

function handleModalClose() {
  selectedResume.value = null;
  commit("CLEAR_RESUMES");
}


</script>

<style lang="scss">
.cpanel-view {
  @media only screen and (min-width: 0) {
  .table {
    .customize-table {
      --easy-table-header-font-color: #fff;
      --easy-table-header-background-color: #1eb386;
      --easy-table-header-font-size: 18px;
      --easy-table-body-row-hover-background-color: #8c8c8c;
      --easy-table-body-row-hover-font-color: #fff;
      --easy-table-header-item-padding: 15px 10px;
      .vue3-easy-data-table__main {
      overflow-y: scroll;
      height: 73vh;
      }
      .vue3-easy-data-table__header {
        position: sticky;
        top: 0;
        z-index: 1;
      }
      .premium-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }
  }
  .search-user {
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    background-color: white;
    transition: all 0.3s ease;
    color: #333;
    outline: none;
    margin-bottom: 20px;
    &:focus {
      border-color: #1eb386;
      box-shadow: 0 0 0 3px rgba(30, 179, 134, 0.1);
    }
  }
  }
}
</style>