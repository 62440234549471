<template>
  <router-view />
</template>

<script setup>
  import {useStore} from "vuex";

  const { state, dispatch, commit } = useStore()
  dispatch('check_login')
</script>

<style lang="scss">

</style>
