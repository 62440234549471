import axios from "axios"
import {api} from "./api"
import router from "@/router";

export const state = {
  user: {
    originalFileName: '',
    templateCV: ''
  },
  
}

export const mutations = {
  SET_AUTH (state, {key, data}) {
    state[key] = data
  },
  SET_TEMPLATE (state, template) {
    state.user.templateCV = template
}
}

export const actions = {
  async login ({commit}, user) {
    try {
      const url = `${api.auth}/login`
      const { data } = await axios.post(url, user)
      commit('SET_AUTH', {key: 'user', data})
      await router.push('/cpanel')
    } catch (err) {
      console.log(err.response)
    }
  },
  async check_login ({commit}) {
    try {
      const url = `${api.auth}/login`
      const { data } = await axios.get(url)
      commit('SET_AUTH', {key: 'user', data})
    } catch (err) {
      console.log(err.response)
      await router.push('/login')
    }
  }
}