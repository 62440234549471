<template>
  <main class="login-page">
    <div>
      <h1>Login</h1>

      <form @submit.prevent="handleSubmit">
        <BaseInput name="email"
                   @input="handleInput" />
        <BaseInput name="password"
                   type="password"
                   @input="handleInput" />
        <button>
          Login
        </button>
      </form>
    </div>
  </main>
</template>

<script setup>
  import BaseInput from "@/components/BaseInput.vue";
  import {ref} from "vue";
  import {useStore} from "vuex";

  const { dispatch } = useStore()
  const user = ref({email: "", password: ""});

  function handleInput ({name, value}) {
    user.value[name] = value;
  }

  function handleSubmit () {
    dispatch('login', user.value)
  }
</script>

<style lang="scss">
  .login-page {
    @media only screen and (min-width: 0) {
      margin-top: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        width: 100%;
        max-width: 300px;
      }

      h1 {
        text-align: center;
      }

      .base-input {
        margin-top: 16px;
      }

      form button {
        width: 100%;
        margin-top: 24px;
        border: none;
        padding: 8px;
        font-size: 16px;
        font-weight: bold;
        background-color: #1eb386;
        color: white;
        border-radius: 4px;
      }
    }
  }
</style>