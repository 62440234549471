<template>
  <main class="errors-view">
    <BaseModal>
      <div class="json-result-wrapper">
        <pre>{{ error }}</pre>
      </div>
    </BaseModal>

    <h1>
      Errors views
    </h1>
    <div class="text-right m-b-24">
      <input type="text"
             placeholder="search ID"
             class="search-input"
             @input="handleSearch" />
    </div>
    <div class="table">  
      <EasyDataTable :headers="tableHeader"
                     :items="data"
                     :rows-per-page="100"
                     :buttons-pagination="false"
                      header-text-direction="center"
                      table-class-name="customize-table"
                      @click-row="handleRowClick"
                      border-cell
                      alternating >
          <template #item-isPremium="{ isPremium }">
            <div class="premium-icon" v-if="isPremium" v-html="tableHeader[4].icon"></div>
          </template>
      </EasyDataTable>
    </div>
  </main>
</template>

<script setup>
  import { useStore } from "vuex";
  import { computed, onMounted } from "vue";
  import BaseModal from "@/components/BaseModal.vue";

  const { state, dispatch, commit } = useStore()
  onMounted(() => {
    dispatch('get_errors')
  })
  const data = computed(() => state.errors.data)
  const error = computed(() => state.errors.error)
  const tableHeader = computed(() => state.errors.tableHeader)
  function handleRowClick ({_id}) {
    dispatch('get_error', _id)
  }

  function handleSearch (ev) {
    commit('SEARCH_ERROR', ev.target.value)
  }

</script>

<style lang="scss">
  @import "../styles/vars.scss";

  .errors-view {
    @media only screen and (min-width: 0) {
      .json-result-wrapper {
        overflow-y: scroll;
        max-height: 80vh;
      }
      .table {
        .customize-table {
          --easy-table-header-font-color: #fff;
          --easy-table-header-background-color: #1eb386;
          --easy-table-header-font-size: 18px;
          --easy-table-body-row-hover-background-color: #8c8c8c;
          --easy-table-body-row-hover-font-color: #fff;
          --easy-table-header-item-padding: 15px 10px;
          .vue3-easy-data-table__main {
          overflow-y: scroll;
          height: 73vh;
          cursor: pointer;
          }
          .vue3-easy-data-table__header {
            position: sticky;
            top: 0;
            z-index: 1;
          }
          .premium-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }

      }
      .search-input {
        padding: 10px 20px;
        font-size: 16px;
        border: 2px solid #e0e0e0;
        border-radius: 8px;
        background-color: white;
        transition: all 0.3s ease;
        color: #333;
        outline: none;
        &:focus {
          border-color: #1eb386;
          box-shadow: 0 0 0 3px rgba(30, 179, 134, 0.1);
        }
      }
    }
  }
</style>